/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import LogService from "../../services/log.service";
import ConsoleHelper from "../../utils/consoleHelper";
import React, { useEffect, useRef, useState } from "react";
import CommonService from "../../services/common.service";
import { useCode } from "../../contextProviders/CodeContext";
import CodeEditor from "../../components/Interview/CodeEditor";
import { useToast } from "../../contextProviders/ToastContext";
import VideoPreview from "../../components/Common/VideoPreview";
import InterviewService from "../../services/interview.service";
import BotComponent from "../../components/Interview/BotComponent";
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import backgroundImage1 from './../../assets/images/background1.svg';
import backgroundImage2 from './../../assets/images/background2.svg';
import { getBrowserInfo, isSafariBrowser } from "../../utils/common";
import VoiceVisualizer from "../../components/Common/VoiceVisualizer";
import { useAnomalies } from "../../contextProviders/AnomaliesContext";
import { BPO_OPS_ANSWER_TYPE, RESUME_STAGES } from "../../utils/constants";
import { useSessionContext } from "../../contextProviders/SessionContext";
import BotVoiceVisualizer from "../../components/Common/BotVoiceVisualizer";
import TabSwitchDetector from "../../components/Interview/TabSwitchDetector";
import { useMediaContext } from "../../contextProviders/InterviewMediaContext";
import InterviewRoomFooter from "../../components/Interview/InterviewRoomFooter";
import InterviewRoomHeader from "../../components/Interview/InterviewRoomHeader";
import RecordingUploadComponent from "../../components/Common/RecordingUploadComponent";
import MultiMonitorDetectedPopup from "../../components/Common/MultiMonitorDetectedPopup";
import InterviewPermissionDialog from "../../components/Interview/InterviewPermissionDialog";
import InterviewDisconnectPopup from "../../components/Interview/InterviewDisconnectPopup";
import NetworkManager from "../../utils/networkManager";

const CHUNK_DURATION = 20;
const HEART_BEAT_INTERVAL = 30 * 1000; // 30 seconds 
const NETWORK_CHECK_INTERVAL = 2 * 60 * 1000; // 2 minutes

const InterviewRoom = () => {
    
    const hasRetried = useRef(false);
    const retryLeft = useRef(3);
    const audioRef = useRef(null);

    const { code, setCode } = useCode();
    const codeRef = useRef(code);

    const azureToken = useRef();
    const azureRegion = useRef();
    const navigate = useNavigate();
    const audioElementRef = useRef();

    const destination = useRef(null);
    const mediaRecorder = useRef(null);
    const candidateSpeechRef = useRef('');
    const audioElementSource = useRef(null);
    const interviewEventTypeRef = useRef(null);

    const questionIDRef = useRef(null);
    const networkManagerRef = useRef();
    const heartbeatIntervalRef = useRef();

    const {showSuccessMessage} = useToast();
    
    const isExitedOrCompleted = useRef(false);
    const {sessionInfo} = useSessionContext();
    const tokenRefreshIntervalRef = useRef(null);
    const [analyser, setAnalyser] = useState(null);
    const [dataArray, setDataArray] = useState(null);
    const [recognizer, setRecognizer] = useState(null);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [isAnswerSent, setIsAnswerSent] = useState(false);

    const [chunksStatus, setChunksStatus] = useState({});
    const [hideTranscript, setHideTranscript] = useState(false);
    const [showCodeEditor, setShowCodeEditor] = useState(false);

    const [showDisconnectPopup, setShowDisconnectPopup] = useState(false);
    const [showFacedDetectionPopup, setShowFacedDetectionPopup] = useState(false);

    const token = sessionInfo?.token || localStorage.getItem('sessionToken');

    const audioContext = useRef(new (window.AudioContext || window.webkitAudioContext)({sampleRate: 48000}));

    let {
        displayStream, 
        audioStream, 
        videoStream, 
        setAudioStream,
        setDisplayStream,
        setVideoStream
    } = useMediaContext();

    const {
        setState,
        connection,
        isRecording,
        isBotSpeaking,
        setConnection,
        setIsRecording,
        clearTranscripts,
        clearMessageQueue,
        handleBotMessages,
        isPermissionsVerified
    } = useSessionContext();


    const isSpeakingRef = useRef(isSpeaking);
    const isBotSpeakingRef = useRef(isBotSpeaking);
    const showCodeEditorRef = useRef(showCodeEditor);
    const {anomaliesData, uploadAnomalies} = useAnomalies();

    const toggleTranscriptView = () => setHideTranscript(!hideTranscript);

    const isPermissionsMissing = () => {
        if(!audioStream || !displayStream || !videoStream)
            return true;
        return false;
    }

    const isStreamsActive = () => {
        if(!audioStream?.active || !displayStream?.active || !videoStream?.active)
            return false;
        return true;
    }
    
    const initializeRecognizer = async () => {
        let responseData = null;
        try {
            const response = await CommonService.getSpeechToken(token);
            responseData = response.data;
            azureToken.current = responseData?.token;
            azureRegion.current = responseData?.region;
        } catch (error) {
            navigate(-1);
        }

        const speechConfig = SpeechSDK.SpeechConfig.fromAuthorizationToken(azureToken.current, azureRegion.current);
        speechConfig.speechRecognitionLanguage = 'en-IN';

        const audioConfig = SpeechSDK.AudioConfig.fromStreamInput(audioStream);
        
        const recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
    
        recognizer.recognized = (s, e) => {
            ConsoleHelper.log(`recognizer.recognized: e?.result?.text: ${e?.result?.text}  e.result.reason: ${e.result.reason} isBotSpeaking: ${isBotSpeaking}`);
            LogService.uploadLog(token, sessionInfo?.session_id, `recognizer.recognized ${e.result.reason} ${e.result.text}`);
            if (e.result.reason === SpeechSDK.ResultReason.RecognizedSpeech && !isBotSpeaking && !isAnswerSent) {
                if(!e?.result?.text || e?.result?.text?.length === 0) return;
                
                if(e.result.text?.trim() !== 'Play.' && e.result.text.trim() !== 'BJP.'){
                    candidateSpeechRef.current += ' ' + e.result.text;
                }
            }
            else if (e.result.reason === SpeechSDK.ResultReason.NoMatch && candidateSpeechRef?.current && candidateSpeechRef?.current?.trim()?.length > 1) {
                setIsSpeaking(false);
                LogService.uploadLog(token, sessionInfo?.session_id, 'Stop speaking');
            }
        };

        recognizer.canceled = () => {
            ConsoleHelper.log("NOMATCH: Speech could not be recognized.");
            LogService.uploadLog(token, sessionInfo?.session_id, `recognizer.canceled`);
        };
    
        setRecognizer(recognizer);
        startListening(recognizer);

        return recognizer;
    };

    useEffect(() => {
        if(anomaliesData.face_missing !== 0 && anomaliesData.face_missing % 10 === 0)
            setShowFacedDetectionPopup(true)

    }, [anomaliesData.face_missing]);

    useEffect(() => {
        isSpeakingRef.current = isSpeaking;
        isBotSpeakingRef.current = isBotSpeaking;
        showCodeEditorRef.current = showCodeEditor;
    }, [isSpeaking, isBotSpeaking, showCodeEditor]);

    useEffect(() => {
        window.history.pushState(null, null, window.location.href);
    
        const handleBackButton = () => {
          window.history.go(1); // Forces the user to stay on the current page
        };
    
        window.onpopstate = handleBackButton;
    
        return () => {
          window.onpopstate = null; // Cleanup function
        };
      }, []);


    useEffect(() => {
        async function checkNetworkSpeed() {
            try {
                LogService.uploadLog(token, sessionInfo?.session_id, 'Checking network speed');
                const pingSpeed = await NetworkManager.measurePing();
                LogService.uploadLog(token, sessionInfo?.session_id, `pingSpeed: ${pingSpeed} milliseconds`);
                const downloadSpeed = await NetworkManager.measureDownloadSpeed();
                LogService.uploadLog(token, sessionInfo?.session_id, `downloadSpeed: ${downloadSpeed} MBps`);
            } catch (error) {
                LogService.uploadLog(token, sessionInfo?.session_id, `failed to check network speed ${JSON.stringify(error)}`);
            }
        }
    
        // Start network check initially
        checkNetworkSpeed();
    
        if (isConnected) {
            initializeRecognizer();
    
            // Refresh token every 5 minutes
            tokenRefreshIntervalRef.current = setInterval(async () => {
                try {
                    const response = await CommonService.getSpeechToken(token);
                    if (response.status === 200) {
                        const newToken = response.data.token;
                        setRecognizer((prevRecognizer) => {
                            if (newToken && prevRecognizer) prevRecognizer.authorizationToken = newToken;
                            return prevRecognizer;
                        });
                    }
                } catch (error) {
                    LogService.uploadLog(token, sessionInfo?.session_id, `Token refresh failed: ${JSON.stringify(error)}`);
                }
            }, 5 * 60 * 1000);
        }
    
        // Network speed check interval
        networkManagerRef.current = setInterval(checkNetworkSpeed, NETWORK_CHECK_INTERVAL);
    
        return () => {
            if (tokenRefreshIntervalRef.current) {
                clearInterval(tokenRefreshIntervalRef.current);
                LogService.uploadLog(token, sessionInfo?.session_id, 'Cleared token refresh interval');
            }
    
            if (networkManagerRef.current) {
                clearInterval(networkManagerRef.current);
                LogService.uploadLog(token, sessionInfo?.session_id, 'Cleared network speed check interval');
            }
    
            if (recognizer) recognizer.close(); // Proper cleanup
        };
    
    }, [isConnected, token, sessionInfo, setRecognizer]);

    useEffect(() => {
        codeRef.current = code;
    }, [code]);

    async function handleWebSocketError(event, message) {
        setShowDisconnectPopup(true);
        LogService.uploadLog(
          token,
          sessionInfo?.session_id,
          `${message} ${JSON.stringify(event || {})}`
        );
        setConnection(null);
        if (isRecording) 
            setIsRecording(false);
        setIsConnected(false);
        await stopListening();   
        await uploadAnomalies(sessionInfo?.token || token);
        await clearResources();
    }
     
    useEffect(() => {
        audioElementRef.current = document.getElementById('bot-audio');
        
        async function initiateConnection(){
            let websocketConnection = null;

            const {browserName, fullVersion} = await getBrowserInfo();
            let startWithQuestion = false;

            if (!isSpeakingRef.current && candidateSpeechRef?.current?.trim().length === 0)
                startWithQuestion = true;
            else if (!showCodeEditorRef.current && codeRef.current?.trim().length === 0)
                startWithQuestion = true;

            LogService.uploadLog(token, sessionInfo?.session_id, `initiateConnection browserName: ${browserName} browserVersion: ${fullVersion} startWithQuestion: ${startWithQuestion}`);

            if(sessionInfo?.interview_type === RESUME_STAGES.INITIAL_SCREENING)
                websocketConnection = InterviewService.connectToInitialInterviewBot(sessionInfo?.token || token, startWithQuestion);
            if(sessionInfo?.interview_type === RESUME_STAGES.EXIT_INTERVIEW)
                websocketConnection = InterviewService.connectToExitInterviewBot(sessionInfo?.token || token, startWithQuestion);
            else if(sessionInfo?.interview_type === RESUME_STAGES.TECHNICAL_SCREENING)
                websocketConnection = InterviewService.connectToTechnicalInterviewBot(sessionInfo?.token || token, startWithQuestion);
            else if(sessionInfo?.interview_type === RESUME_STAGES.VIDEO_PROFILING)
                websocketConnection = InterviewService.connectToVideoProfilingInterviewBot(sessionInfo?.token || token, startWithQuestion);
            else if(sessionInfo?.interview_type === RESUME_STAGES.BPO_HR_SCREENING)
                websocketConnection = InterviewService.connectToBPOInitialInterviewBot(sessionInfo?.token || token, startWithQuestion);
            else if(sessionInfo?.interview_type === RESUME_STAGES.OPS_SCREENING && sessionInfo?.bpo_ops_answer_type === BPO_OPS_ANSWER_TYPE.TEXT)
                websocketConnection = InterviewService.connectToBPOpsTextInterviewBot(sessionInfo?.token || token, startWithQuestion);
            else if(sessionInfo?.interview_type === RESUME_STAGES.OPS_SCREENING && sessionInfo?.bpo_ops_answer_type === BPO_OPS_ANSWER_TYPE.VOICE)
                websocketConnection = InterviewService.connectToBPOpsVoiceInterviewBot(sessionInfo?.token || token, startWithQuestion);
            setConnection(websocketConnection);

            hasRetried.current = false;
        
            websocketConnection.onopen = (event) => {
                LogService.uploadLog(token, sessionInfo?.session_id, `websocketConnection open ${JSON.stringify(event || {})}`);
                if(!isSpeakingRef.current && candidateSpeechRef.current?.trim().length > 0){
                    const logData = {
                        interviewState: 1,
                        type: "CANDIDATE_RESPONSE",
                        questionID: questionIDRef.current,
                        candidateVoiceText: candidateSpeechRef.current,
                    };
    
                    const requestData = JSON.stringify(logData);
                    connection.send(requestData);
                }
                else if(!showCodeEditorRef.current && codeRef.current?.trim().length === 0){
                    const logData = {
                        interviewState: 1,
                        type: "CANDIDATE_RESPONSE",
                        questionID: questionIDRef.current,
                        candidateVoiceText: codeRef.current,
                    };
    
                    const requestData = JSON.stringify(logData);
                    connection.send(requestData);
                }

                setIsConnected(true);
                clearTranscripts();

                heartbeatIntervalRef.current = setInterval(() => {
                    LogService.uploadLog(token, sessionInfo?.session_id, `Heartbeat connection.readyState ${websocketConnection.readyState}`);
                    const payload = {
                        type: 'HEART_BEAT',
                        speaker: isSpeakingRef.current || showCodeEditorRef.current ? 
                            'candidate' : isBotSpeakingRef.current ? 
                            'athmiya' : 'silence',
                    };
                    websocketConnection.send(JSON.stringify(payload));
                    LogService.uploadLog(token, sessionInfo?.session_id, `Heartbeat sent: ${JSON.stringify(payload)}`);
                }, HEART_BEAT_INTERVAL);
            };
        
            websocketConnection.onmessage = async (event) => {

                const data = JSON.parse(event.data);
                const {
                    type,
                    status,
                    result, 
                    viseme, 
                    counter,
                    questionID, 
                    audio_stream,
                    is_completed, 
                    remaining_time, 
                    is_text_question,
                    is_coding_question,
                } = data;

                const logData = {
                    type,
                    result,
                    status,
                    counter, 
                    questionID,
                    is_completed,
                    is_coding_question,
                    is_text_question,
                    visemeLength: viseme?.length,
                    visemeIds: viseme?.map((item) => item?.viseme_id)?.join(",")
                }

                questionIDRef.current = questionID;
                
                LogService.uploadLog(
                    token, 
                    sessionInfo?.session_id, 
                    `websocketConnection onmessage logData=${JSON.stringify(logData)}`
                );
                
                const questionInfo = {
                    type,
                    viseme,
                    questionID,
                    is_completed,
                    remaining_time,
                    message: result,
                    audio_stream,
                    sender: 'Athmiya',
                    is_coding_question,
                    is_text_question
                }
                if(!isExitedOrCompleted.current && (type === 'NEXT_QUESTION' || type === 'RECEIVED_CANDIDATE_TEXT' || type === 'QUESTION_ACKNOWLEDGEMENT') && result && audio_stream){
                    setIsAnswerSent(false);

                    if(code) setCode(null);
                    if(codeRef.current) 
                        codeRef.current = null;
                    if(candidateSpeechRef.current)
                        candidateSpeechRef.current = '';

                    if(showCodeEditorRef.current)
                        setShowCodeEditor(false);
                    
                    handleBotMessages(questionInfo);    
                }
                else if (!isExitedOrCompleted.current && type === 'INTERRUPT'){
                    setIsAnswerSent(false);

                    const requestData = {
                        interviewState: 1,
                        type: "CANDIDATE_RESPONSE",
                        questionID: questionIDRef.current,
                        candidateInputText: showCodeEditorRef.current ? 
                            codeRef.current : candidateSpeechRef.current,
                    };

                    if(showCodeEditorRef.current){
                        setShowCodeEditor(false);
                        showSuccessMessage({
                            life: 3000,
                            summary: 'Auto Submission Successful',
                            detail: 'Your code has been auto-submitted successfully.'
                        });
                        setCode(null);
                        codeRef.current = null;
                    }   

                    handleBotMessages(questionInfo);

                    const payload = JSON.stringify(requestData);
                    LogService.uploadLog(token, sessionInfo?.session_id, 
                        `INTERRUPT ${websocketConnection?.readyState}  ${payload} codeRef.current:- ${codeRef.current} candidateSpeechRef.current:- ${candidateSpeechRef.current}`);

                    if (websocketConnection && websocketConnection.readyState === WebSocket.OPEN) {
                        websocketConnection.send(payload);
                        LogService.uploadLog(token, sessionInfo?.session_id, `INTERRUPT after sent ${JSON.stringify(payload)}`);
                            
                        if(candidateSpeechRef.current)
                            candidateSpeechRef.current = '';
                    }
                }
            };

            websocketConnection.onerror = (event) => {
                LogService.uploadLog(token, sessionInfo?.session_id, 'websocketConnection.onerror');
                // if(interviewEventTypeRef.current !== "EXITED" &&  interviewEventTypeRef.current !== "COMPLETED"){
                //     retryCountRef.current += 1;
                //     initiateConnection();
                //     LogService.uploadLog(token, sessionInfo?.session_id, 'websocketConnection.onerror if');
                // }
                // else LogService.uploadLog(token, sessionInfo?.session_id, 'websocketConnection.onerror else');
                // else handleWebSocketError(event, 'websocketConnection.onclose');
            };
            
            websocketConnection.onclose = (event) => {
                if (heartbeatIntervalRef.current) {
                    LogService.uploadLog(token, sessionInfo?.session_id, 'Heartbeat clearInterval');
                    clearInterval(heartbeatIntervalRef.current);
                    heartbeatIntervalRef.current = null;
                }

                const shouldReconnect = websocketConnection.readyState !== WebSocket.OPEN 
                    && interviewEventTypeRef.current !== "EXITED" &&  interviewEventTypeRef.current !== "COMPLETED" 
                    && !hasRetried.current && retryLeft.current > 0 && isExitedOrCompleted.current === false;
                
                if(retryLeft.current <= 0){
                    if (!audioElementRef?.current?.paused)
                        audioElementRef.current.pause();
                    
                    handleWebSocketError(event, 'websocketConnection.onclose');
                }
                else if(shouldReconnect){
                    hasRetried.current = true;
                    retryLeft.current -= 1;
                    initiateConnection();
                    LogService.uploadLog(token, sessionInfo?.session_id, 'websocketConnection.onclose if');
                }
                else LogService.uploadLog(token, sessionInfo?.session_id, 'websocketConnection.onclose else');
            };
        }

        if(!isPermissionsMissing() && isStreamsActive() && audioElementRef.current){
            startRecording();
            initiateConnection();
        }
    }, [isPermissionsMissing(), isStreamsActive()]);

    useEffect(() => {
        async function beforeInterviewClose(){
            try {
                if(connection && interviewEventTypeRef.current === 'EXITED'){
                    if (connection?.readyState === WebSocket.OPEN)
                        connection.send(JSON.stringify({ interviewState: 0 }));
                }
    
                if (connection) {
                    connection?.close();
                    setConnection(null);
                }

                setIsUploading(false);
                if(interviewEventTypeRef.current === 'DISCONNECT') navigate(-1);
                else{
                    setState(interviewEventTypeRef.current);
                    LogService.uploadLog(token, sessionInfo?.session_id, `beforeInterviewClose end interviewEventTypeRef.current:  ${interviewEventTypeRef.current}`);
                }
    
            } catch (error) {
                setIsUploading(false);
                setState(interviewEventTypeRef.current)
                LogService.uploadLog(token, sessionInfo?.session_id, `Error during ${interviewEventTypeRef.current} process`);
                ConsoleHelper.error(`Error during ${interviewEventTypeRef.current} process: ${JSON.stringify(error)}`);
            }
        }

        if((interviewEventTypeRef.current === 'EXITED' || interviewEventTypeRef.current === 'COMPLETED' || interviewEventTypeRef.current === 'DISCONNECT') && !Object.values(chunksStatus).includes(false)){
            LogService.uploadLog(token, sessionInfo?.session_id, `isUploading: ${isUploading}  !Object.values(chunksStatus).includes(false) ${JSON.stringify(Object.values(chunksStatus).join(","))}`);
            beforeInterviewClose();
        }
        else if((interviewEventTypeRef.current === 'EXITED' || interviewEventTypeRef.current === 'COMPLETED' || interviewEventTypeRef.current === 'DISCONNECT') && Object.values(chunksStatus).includes(false)){
            LogService.uploadLog(token, sessionInfo?.session_id, `isUploading: ${isUploading} Object.values(chunksStatus).includes(false) ${JSON.stringify(Object.values(chunksStatus).join(","))}`);
            if(!isUploading) setIsUploading(true);
        }

    }, [Object.values(chunksStatus), connection])

    useEffect(() => {
        let TIMEOUT = null;
        if((isPermissionsMissing()  || !sessionInfo?.token) && !isUploading && showDisconnectPopup === false){
            TIMEOUT = setTimeout(() => {
                if((isPermissionsMissing() || !isStreamsActive() || !sessionInfo?.token) && showDisconnectPopup === false ){
                    navigate(-1);
                }
            }, 10000);
        }

        return () => {
            clearTimeout(TIMEOUT);
        };
    }, [audioStream, videoStream, displayStream, sessionInfo?.token])

    useEffect(() => {
        const getUserMedia = async () => {
            try {
                const stream = audioStream;
                const analyserNode = audioContext.current.createAnalyser();
                analyserNode.fftSize = 2048;
                const source = audioContext.current.createMediaStreamSource(stream);
                source.connect(analyserNode);

                const bufferLength = analyserNode.frequencyBinCount;
                const dataArray = new Uint8Array(bufferLength);
                setAnalyser(analyserNode);
                setDataArray(dataArray);
            } 
            catch (error) {
                ConsoleHelper.error(`Error accessing microphone: ${JSON.stringify(error)}`);
            }
        };

        getUserMedia();
    }, [audioStream]);
    
    useEffect(() => {
        if (!analyser || !dataArray) return;

        let silenceTimeout = null;
        const SPEECH_THRESHOLD = 80; // Adjust the threshold as needed
        const SILENCE_DURATION = sessionInfo?.interview_type === RESUME_STAGES.BPO_HR_SCREENING ? 100 : 1500; // Silence timeout duration
        const SPEECH_FREQUENCY_MIN = 6; // Approx. corresponds to 300 Hz (index varies based on sample rate and FFT size)
        const SPEECH_FREQUENCY_MAX = 40; // Approx. corresponds to 3000 Hz
    
        const intervalId = setInterval(() => {
            analyser.getByteFrequencyData(dataArray);
    
            // Filter to keep only frequencies in the speech range (300Hz - 3000Hz)
            const filteredData = dataArray.slice(SPEECH_FREQUENCY_MIN, SPEECH_FREQUENCY_MAX);
            const sum = filteredData.reduce((a, b) => a + b, 0);
            const average = sum / filteredData.length;
    
            ConsoleHelper.log(`Filtered average: ${average}`);
            ConsoleHelper.log(`isSpeaking: ${isSpeaking}`);
    
            // Detect speaking based on the filtered average
            if (average > SPEECH_THRESHOLD) {
                if (!isSpeaking) {
                    setIsSpeaking(true);
                    ConsoleHelper.log(`User started speaking ${average}`);
                }
    
                if (silenceTimeout) clearTimeout(silenceTimeout);
            } else {
                silenceTimeout = setTimeout(() => {
                    if(isSpeaking){
                        setIsSpeaking(false);
                        ConsoleHelper.log(`User stopped speaking ${average}`);
                    }
                }, SILENCE_DURATION);
            }
        }, 250);
    
        return () => {
            ConsoleHelper.log("Cleaning up detectSpeech");
            if (silenceTimeout) clearTimeout(silenceTimeout);
            clearInterval(intervalId);
        };
    }, [analyser, dataArray, isSpeaking]);

    useEffect(() => {
        if(candidateSpeechRef.current != null && candidateSpeechRef.current?.trim()?.length > 0){
            if(connection && !isSpeaking && !isBotSpeaking && !isAnswerSent){
                enabledMic(false);
                setIsAnswerSent(true);
                const questionID = questionIDRef.current;

                const logData = {
                    questionID,
                    interviewState: 1,
                    type: "CANDIDATE_RESPONSE",
                    candidateVoiceText: candidateSpeechRef.current,
                };

                const requestData = JSON.stringify(logData);
                if(connection && connection.readyState === WebSocket.OPEN){
                    connection.send(requestData);
                    candidateSpeechRef.current = '';
                    LogService.uploadLog(token, sessionInfo?.session_id, `sent candidate requestData: ${JSON.stringify(requestData)}`);
                }
            }
        }
    }, [isSpeaking, isAnswerSent]);

    const startMediaRecorder = () => createMediaRecorder();

    function captureAudioStream(audioElement) {
        if (audioElement?.captureStream) {
            return audioElement.captureStream();
        } else if (audioElement?.mozCaptureStream) {
            return audioElement.mozCaptureStream(); 
        } else {
            const source = audioContext.current.createMediaElementSource(audioElement);
            const destination = audioContext.current.createMediaStreamDestination();
            source.connect(destination);
            return destination.stream;
        }
    }

    const createMediaRecorder = () => {
        LogService.uploadLog(token, sessionInfo?.session_id, 'createMediaRecorder');
        
        if (typeof MediaRecorder === 'undefined') {
            LogService.uploadLog(token, sessionInfo?.session_id, `createMediaRecorder MediaRecorder is not supported in this browser`);
            ConsoleHelper.error('MediaRecorder is not supported in this browser');
            return;
        }

        try {
            if (!displayStream || !audioStream || !audioElementRef.current ) {
                LogService.uploadLog(token, sessionInfo?.session_id, `One or more required streams are not available`);
                ConsoleHelper.log('One or more required streams are not available');
                return;
            }

            // Cleanup existing resources
            if (mediaRecorder.current) {
                mediaRecorder.current.stop();
                // mediaRecorder.current.ondataavailable = null;
                // mediaRecorder.stream.getTracks().forEach(track => track.stop()); // Ensure tracks are stopped
            }
            if (audioElementSource.current) {
                audioElementSource.current.disconnect();
                audioElementSource.current = null;
            }
            if (destination.current) {
                // destination.current.stream.getTracks().forEach(track => track.stop()); // Stop all tracks in destination stream
                destination.current = null;
            }

            if(!isRecording) setIsRecording(true);

            const micSource = audioContext.current.createMediaStreamSource(audioStream);

            const audioElement = audioElementRef.current;
            const audioElementStream = captureAudioStream(audioElement);

            if (!audioElementSource.current)
                audioElementSource.current = audioContext.current.createMediaStreamSource(audioElementStream);

            if (!destination.current)
                destination.current = audioContext.current.createMediaStreamDestination();

            // Connect both the microphone and audio element to the destination
            micSource.connect(destination.current);
            audioElementSource.current.connect(destination.current);

            const stream = new MediaStream([
                ...displayStream?.getVideoTracks(),
                ...destination.current.stream.getAudioTracks(),
            ]);

            const recorder = new MediaRecorder(stream, { 
                mimeType: isSafariBrowser() ? 'video/mp4;codecs:h264': 'video/mp4; codecs=vp9',
                audioBitsPerSecond: 128000,
                videoBitsPerSecond: 2500000,
            });

            recorder.ondataavailable = handleDataAvailable;
            recorder.start();

            mediaRecorder.current = recorder;
        } catch (error) {
            LogService.uploadLog(token, sessionInfo?.session_id, `createMediaRecorder error ${JSON.stringify(error)}`);
            ConsoleHelper.error(JSON.stringify(error));
        }
    };

    const handleDataAvailable = (event) => {
        LogService.uploadLog(token, sessionInfo?.session_id, `handleDataAvailable event.data.size ${event?.data?.size}`);
        if (event.data.size > 0){
            const blob = new Blob([event.data], { type: 'video/mp4' });
            const fileName = `${Date.now()}.mp4`;
            const data = {
                fileName,
                file: blob,
                token: sessionInfo.token,
                duration: CHUNK_DURATION,
                sessionId: sessionInfo?.session_id,
            }
            setChunksStatus((prevStatus) => ({
                ...prevStatus,
                [fileName]: false,
            }));
            addChunk(data);
        }
    };

    const startListening = async (recognizer) => {
        LogService.uploadLog(token, sessionInfo?.session_id, 'startListening');
        if (recognizer) {
            try {
                await recognizer.startContinuousRecognitionAsync();
                ConsoleHelper.log("Recognition started");
            } catch (err) {
                LogService.uploadLog(token, sessionInfo?.session_id, `Error starting recognition:, ${JSON.stringify(err)}`);
                ConsoleHelper.error(`Error starting recognition:, ${JSON.stringify(err)}`);
            }
        }
    };
    
    const stopListening = async () => {
        LogService.uploadLog(token, sessionInfo?.session_id, 'stopListening');
        if (recognizer) {
            try {
                recognizer.close();
                await recognizer.stopContinuousRecognitionAsync();
                if (tokenRefreshIntervalRef.current)
                    clearInterval(tokenRefreshIntervalRef.current);
                
                setRecognizer(null);
                LogService.uploadLog(token, sessionInfo?.session_id, 'Recognition stopped');
            } catch (err) {
                LogService.uploadLog(token, sessionInfo?.session_id, 'Error stopping recognition');
                ConsoleHelper.log(`Error stopping recognition:, ${JSON.stringify(err)}`);
            }
        }
    };

    const enabledMic = (desiredState = true) => {
        // Log the desired state
        ConsoleHelper.log(`enabledMic ${desiredState}`);
    
        // Check if the desired state is different from the current state
        const audioTracks = audioStream?.getAudioTracks();
    
        if (audioTracks) {
            // Determine the current state based on the tracks' enabled property
            const currentState = audioTracks[0]?.enabled; // Assuming all tracks have the same state
    
            // Only change the state if it's different
            if (currentState !== desiredState) {
                LogService.uploadLog(token, sessionInfo?.session_id, 
                    `Interview Room enabledMic state=${desiredState} is audioStream null=${audioStream === null}`
                );
    
                audioTracks.forEach(track => { track.enabled = desiredState });
            }
        } else {
            LogService.uploadLog(token, sessionInfo?.session_id, 
                `Interview Room audioStream is null; state change not possible`
            );
        }
    };

    const startRecording = () => {
        try {
            LogService.uploadLog(token, sessionInfo?.session_id, 'startRecording');
            if (!mediaRecorder.current && !isRecording)
                createMediaRecorder();
            
        } catch (error) {
            LogService.uploadLog(token, sessionInfo?.session_id, `startRecording error ${JSON.stringify(error)}`);
            ConsoleHelper.error(JSON.stringify(error));
        }
    };

    const stopRecording = () => {
        // Clear the interval for chunk handling if it exists
        // if (chunkIntervalRef.current) {
        //     clearInterval(chunkIntervalRef.current);
        //     chunkIntervalRef.current = null; // Reset the ref to prevent unintended usage
        // }
    
        // Helper function to stop all tracks of a stream
        const stopStreamTracks = (stream, setStream) => {
            if (stream) {
                stream.getTracks().forEach(track => {
                    track.stop(); // Stop each track
                    ConsoleHelper.log(`Stopped track: ${track.kind}`);
                });
                setStream(null); // Clear the stream state
            }
        };
    
        // Stop and release each stream
        stopStreamTracks(displayStream, setDisplayStream);
        stopStreamTracks(videoStream, setVideoStream);
        stopStreamTracks(audioStream, setAudioStream);
    
        // Ensure the recording state is updated
        if(isRecording) setIsRecording(false);
        ConsoleHelper.log("Recording stopped");
    };
    
    const addChunk = (chunk) => {
        LogService.uploadLog(sessionInfo.token, sessionInfo?.session_id, `addChunk ${chunk?.['fileName']}`);
        const worker = new Worker(new URL('../../utils/uploadWorker.js', import.meta.url));

        worker.postMessage({
            ...chunk,
            retries: 3, // Number of retries
        });

        worker.onmessage = (e) => {
            const { fileName, success, error, retry, retries } = e.data;

            if (success) {
                LogService.uploadLog(sessionInfo.token, sessionInfo?.session_id, `uploaded ${fileName}`);
                setChunksStatus((prevStatus) => ({
                    ...prevStatus,
                    [fileName]: true,
                }));
                worker.terminate();
            }

            if (retry) {
                worker.postMessage({
                    ...e.data,
                    retries,
                });
                LogService.uploadLog(sessionInfo.token, sessionInfo?.session_id, `retry ${fileName}`);
            }

            if (error) {
                setChunksStatus((prevStatus) => ({
                    ...prevStatus,
                    [fileName]: true,
                }));
                LogService.uploadLog(sessionInfo.token, sessionInfo?.session_id, `Failed to upload ${fileName}`);
                worker.terminate();
            }
        };

    };

    const submitCode = (code) => {
        try {
            if(!code || code?.trim()?.length === 0) return;
            setShowCodeEditor(false);
            enabledMic(false);
            setCode(null);
            codeRef.current = null;

            LogService.uploadLog(token, sessionInfo?.session_id, `submitCode questionId:${questionIDRef.current} candidateInputText:${code}`);
            const requestData = JSON.stringify({
                interviewState: 1,
                questionID: questionIDRef.current,
                type: "CANDIDATE_RESPONSE",
                candidateInputText: code,
            });
            if (connection && connection.readyState === WebSocket.OPEN) {
                connection.send(requestData);
                showSuccessMessage({
                    life: 4000,
                    summary: 'Code Summited', 
                    detail: 'Your code has been submitted successfully.'
                })
            }
            LogService.uploadLog(token, sessionInfo?.session_id, `sent answer questionId:${questionIDRef.current} candidateInputText:${code}`);
        } catch (error) {
            enabledMic(true);
            ConsoleHelper.error(`submitCode got error: ${JSON.stringify(error)}`);
            LogService.uploadLog(token, sessionInfo?.session_id, `failed to submit ${JSON.stringify(error)} coding answer questionId:${questionIDRef.current} candidateInputText:${code}`);
        }
    }

    const submitTextAnswer = (answer) => {
        try {
            if(!answer || answer?.trim()?.length === 0) return;
            setShowCodeEditor(false);
            enabledMic(false);
            LogService.uploadLog(token, sessionInfo?.session_id, `submitTextAnswer questionId:${questionIDRef.current} candidateInputText:${answer}`);
            const requestData = JSON.stringify({
                interviewState: 1,
                questionID: questionIDRef.current,
                type: "CANDIDATE_RESPONSE",
                candidateInputText: answer,
            });
            if (connection && connection.readyState === WebSocket.OPEN) {
                connection.send(requestData);
            }
            LogService.uploadLog(token, sessionInfo?.session_id, `sent answer questionId:${questionIDRef.current} candidateInputText:${answer}`);
        } catch (error) {
            ConsoleHelper.error(`submitTextAnswer got error , ${JSON.stringify(error)}`);
            LogService.uploadLog(token, sessionInfo?.session_id, `failed to submit ${JSON.stringify(error)} submitTextAnswer questionId:${questionIDRef.current} candidateInputText:${answer}`);
        }
    }

    const hideEditor = () => {
        setShowCodeEditor(false);
        setCode(null);
        codeRef.current = null;
        // enabledMic(true);
    }

    const clearResources = async () => {
        if (mediaRecorder.current && mediaRecorder.current.state !== "inactive")
            mediaRecorder.current.stop();
        
        stopRecording();
        clearTranscripts();
        clearMessageQueue();
    }

    const onExit = async () => {
        LogService.uploadLog(token, sessionInfo?.session_id, 'onExit');
        try {
            isExitedOrCompleted.current = true;

            if (!audioElementRef?.current?.paused)
                audioElementRef.current.pause();

            if (connection && connection?.readyState === WebSocket.OPEN){
                connection.send(JSON.stringify({ interviewState: 0 }));
            }

            if (heartbeatIntervalRef.current) {
                LogService.uploadLog(token, sessionInfo?.session_id, 'Heartbeat clearInterval');
                clearInterval(heartbeatIntervalRef.current);
                heartbeatIntervalRef.current = null;
            }

            if (mediaRecorder.current)
                mediaRecorder.current.stop();
            if(isRecording) setIsRecording(false);

            interviewEventTypeRef.current = 'EXITED';

            await stopListening();   
            await uploadAnomalies(sessionInfo?.token || token);
            await clearResources();

        } catch (error) {
            LogService.uploadLog(token, sessionInfo?.session_id, 'Error during exit process');
            ConsoleHelper.error(`Error during exit process: ${JSON.stringify(error)}`);
        }
    };

    const onCompleted = async () => {
        LogService.uploadLog(token, sessionInfo?.session_id, 'onCompleted');
        try {
            isExitedOrCompleted.current = true;

            if (heartbeatIntervalRef.current) {
                LogService.uploadLog(token, sessionInfo?.session_id, 'Heartbeat clearInterval');
                clearInterval(heartbeatIntervalRef.current);
                heartbeatIntervalRef.current = null;
            }
            
            if (mediaRecorder.current)
                mediaRecorder.current.stop();
            if(isRecording) setIsRecording(false);
            interviewEventTypeRef.current = 'COMPLETED';

            await stopListening();   
            await uploadAnomalies(sessionInfo?.token || token);
            await clearResources();

        } catch (error) {
            LogService.uploadLog(token, sessionInfo?.session_id, `Error during completed process ${JSON.stringify(error)}`);
            ConsoleHelper.error(`Error during completed process: ${JSON.stringify(error)}`);
        }
    };

    const onDisconnect = () => {
        try {
            if (mediaRecorder.current)
                mediaRecorder.current.stop();
            if(isRecording) setIsRecording(false);
            setShowDisconnectPopup(false)
            interviewEventTypeRef.current = 'DISCONNECT';
        } catch (error) {
            LogService.uploadLog(token, sessionInfo?.session_id, `Error onDisconnect ${JSON.stringify(error)}`);
        }
    }

    return (
        <div className="bg-primary h-screen w-screen flex flex-col gap-2 3xl:gap-5">
            {showDisconnectPopup && <InterviewDisconnectPopup onDisconnect={onDisconnect}/>}
            {isExitedOrCompleted.current === false && showDisconnectPopup === false && <InterviewPermissionDialog />}
            <MultiMonitorDetectedPopup />
            <TabSwitchDetector />
            <RecordingUploadComponent 
                visible={isUploading}
                chunksStatus={chunksStatus}
            />
            <img
                alt="background" 
                src={backgroundImage1}
                className="absolute bottom-0 right-0"
            />
            <img
                alt="background" 
                src={backgroundImage2}
                className="absolute top-40 left-0"
            />
            <InterviewRoomHeader 
                isRecording={isRecording}
            />
            <div className="px-5 flex-1 flex 3xl:gap-5 m-auto overflow-hidden 3xl:h-[90%] 3xl:w-[90%] justify-center items-center">
                <div className={`relative flex h-full w-full ${hideTranscript ? 'w-full': 'w-4/5'} rounded-lg flex-col items-center ${isSpeaking && !isAnswerSent && !isBotSpeaking ? 'border-[5px] border-[#4DC284]': ''}`}>
                    <VideoPreview 
                        isRecording={isRecording}
                        submitTextAnswer={submitTextAnswer}
                        isExitedOrCompleted={isExitedOrCompleted.current}
                    />
                    <div className="bottom-6 gap-5 right-6 absolute flex justify-center items-center">
                        <BotVoiceVisualizer
                            audioRef={audioRef}
                            audioContext={audioContext.current}
                        />
                        <BotComponent
                            audioRef={audioRef}
                            isConnected={isConnected}
                            onCompleted={onCompleted}
                            isCandidateSpeaking={isSpeaking}
                            token={azureToken.current}
                            region={azureRegion.current}
                            isAnswerSent={isAnswerSent}
                            stopRecording={stopRecording} 
                            startMediaRecorder={startMediaRecorder}
                        />
                    </div>
                    <div className="top-6 gap-5 right-6 absolute flex justify-center items-center">
                        <VoiceVisualizer 
                            isBotSpeaking={isBotSpeaking}
                            isSpeaking={isSpeaking}
                        />
                    </div>
                </div>
            </div>
            <div className={`h-screen w-screen bg-transparent absolute left-0 top-0 z-40 justify-center items-center flex ${showFacedDetectionPopup ? 'visible': 'hidden'}`}>
                <div className='bg-white p-5 rounded-md'>
                    <h2 className='text-primary font-bold text-base 3xl:text-xl'>Face Not Detected</h2>
                    <div className='pt-3 3xl:pt-5 font-normal flex flex-col gap-4 mt-2 justify-center items-center'>
                        <p className='text-sm 3xl:text-base'>Please note, if the face is not visible, the interview will be invalidated</p>
                        <button
                            onClick={() => setShowFacedDetectionPopup(false)} 
                            className='h-9 3xl:h-10 bg-blue text-white w-20 rounded-md font-semibold text-xs 3xl:text-sm'>
                            Close
                        </button>
                    </div>
                </div>
            </div>
            {showCodeEditor && isConnected && 
                <CodeEditor 
                    submitCode={submitCode}
                    hideEditor={hideEditor}
                    isRecording={isRecording}
                    connection={connection}
                />
            }
            <InterviewRoomFooter 
                onExit={onExit}
                isConnected={isConnected}
                stopRecording={stopRecording}
                showCodeEditor={() => {
                    setShowCodeEditor(true);
                }}
                toggleTranscriptView= {toggleTranscriptView}
            />
        </div>
    )
}

export default React.memo(InterviewRoom);
