const log = (value) => {
    // if (process.env.NODE_ENV === 'production') return;
}

const error = (value) => {
    // if (process.env.NODE_ENV === 'production') return;
}
  
const ConsoleHelper = {
    log,
    error
}

export default ConsoleHelper;
  