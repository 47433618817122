import ConsoleHelper from '../utils/consoleHelper';
import React, { createContext, useContext, useState } from 'react';


const NoddingImageContext = createContext();

export const NoddingImageProvider = ({ children }) => {
    const [botNoddingImages, setBotNoddingImages] = useState({});
    const [loadingBotNoddingImages, setLoadingBotNodddingImages] = useState(false);


    const loadNoddingImages = async () => {
        let imageMap = {};
        let extension = '.jpeg';
        for (let i = 0; i <= 57; i++) {
            const img = new Image();
            img.src = `https://skillassessment.blob.core.windows.net/images/bot-images/avatar2/athmiya${i}.jpeg`;
    
            try {
                await img.decode();
                imageMap[i] = img.src;
                ConsoleHelper.log(`Loaded viseme-${i}.png`);
            } catch (error) {
                ConsoleHelper.error(`Failed to load viseme-${i}.png`, error);
            }
        }
    
        return imageMap;
    }

    const downloadNoddingImages = async () => {
        try {
            setLoadingBotNodddingImages(true);
            const preloadedImages = await loadNoddingImages();
            setBotNoddingImages(preloadedImages);
            setLoadingBotNodddingImages(false);
        } catch (error) {
            setLoadingBotNodddingImages(false);
        }
    }

    return (
        <NoddingImageContext.Provider value={{ botNoddingImages, loadingBotNoddingImages, downloadNoddingImages }}>
            {children}
        </NoddingImageContext.Provider>
    );
};

export const useBotNoddingImages = () => useContext(NoddingImageContext);
