import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import InterviewHome from "../pages/Interview/InterviewHome";
import InterviewRoom from "../pages/Interview/InterviewRoom";
import ProtectedRoute from "../components/Auth/ProtectedRoute";
import InterviewLanding from "../pages/Interview/InterviewLanding";
import { SessionProvider } from "../contextProviders/SessionContext";
import { MonitorProvider } from "../contextProviders/MonitorContext";
import { MediaTestProvider } from "../contextProviders/MediaTestContext";
import { AnomaliesProvider } from "../contextProviders/AnomaliesContext";
import { InterviewMediaProvider } from "../contextProviders/InterviewMediaContext";
import { InterviewAuthProvider } from "../contextProviders/InterviewAuthContext";
import { ImageProvider } from "../contextProviders/BotImageContext";
import { CodeProvider } from "../contextProviders/CodeContext";
import { NoddingImageProvider } from "../contextProviders/BotNoddingContext";

export default function InterviewRoutes(){

    useEffect(() => {
        const handlePaste = (e) => {
          e.preventDefault();
          alert("Pasting from outside sources is disabled.");
        };
    
        // Add event listener to block paste
        document.addEventListener('paste', handlePaste);
    
        return () => {
          // Cleanup event listener
          document.removeEventListener('paste', handlePaste);
        };
    }, []);


    useEffect(() => {
        const disableDevTools = (event) => {
          if (
            event.key === "F12" ||
            (event.ctrlKey && event.shiftKey && event.key === "I") ||
            (event.ctrlKey && event.shiftKey && event.key === "J") ||
            (event.ctrlKey && event.shiftKey && event.key === "C") ||
            (event.ctrlKey && event.key === "U")
          ) {
            event.preventDefault();
          }
        };
      
        document.addEventListener("keydown", disableDevTools);
        return () => {
          document.removeEventListener("keydown", disableDevTools);
        };
    }, []);
    
    useEffect(() => {
        const disableCopy = (event) => event.preventDefault();
        const disableContextMenu = (event) => event.preventDefault();
    
        document.addEventListener("copy", disableCopy);
        document.addEventListener("cut", disableCopy);
        document.addEventListener("contextmenu", disableContextMenu);
    
        return () => {
          document.removeEventListener("copy", disableCopy);
          document.removeEventListener("cut", disableCopy);
          document.removeEventListener("contextmenu", disableContextMenu);
        };
    }, []);



    // useEffect(() => {
    //     const handleBeforeUnload = (event) => {
    //       event.preventDefault();
    //       event.returnValue = ""; // Standard behavior to show a dialog
    //     };
    
    //     window.addEventListener("beforeunload", handleBeforeUnload);
    
    //     return () => {
    //       window.removeEventListener("beforeunload", handleBeforeUnload);
    //     };
    //   }, []);

    return (
        <InterviewAuthProvider>
            <InterviewMediaProvider>
                <SessionProvider>
                    <AnomaliesProvider>
                        <MediaTestProvider>
                            <MonitorProvider>
                               <NoddingImageProvider>
                                    <ImageProvider>
                                        <CodeProvider>
                                            <Routes>
                                                <Route path="" element={<InterviewHome />}>
                                                    <Route index path=":id" element={<ProtectedRoute element={<InterviewLanding />} />}/>
                                                    <Route path="room/:id" element={<ProtectedRoute element={<InterviewRoom />} />}/>
                                                    <Route path="*" element={<InterviewHome />}/>
                                                </Route>
                                            </Routes>
                                        </CodeProvider>
                                    </ImageProvider>
                                </NoddingImageProvider>
                            </MonitorProvider>
                        </MediaTestProvider>
                    </AnomaliesProvider>
                </SessionProvider>
            </InterviewMediaProvider>
        </InterviewAuthProvider>
    )
}