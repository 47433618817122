import axios from "axios"
import moment from "moment/moment";

function uploadLog(token, sessionId, message){
    // if(!sessionId || !token || !message) return;
    try {
        axios.post(`${process.env.REACT_APP_API_URL}/v1/screening/logs`, 
            {
                message: `${moment().format("DD-MMM-YYYY HH:mm:ss")} ${message}`,
                session: sessionId
            },
            { headers: { 'Authorization': `Bearer ${token}`}
        })
    } catch (error) {}
}

const LogService = { uploadLog }

export default LogService;

