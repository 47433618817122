import moment from 'moment-timezone';
import ConsoleHelper from "./consoleHelper";

export function getEditorHeaders(){
    return (
        <div className="toolbar-container">
            <span class="ql-formats">
                <select class="ql-font"></select>
                <select class="ql-size"></select>
            </span>
            <span class="ql-formats">
                <button class="ql-list" value="ordered" />
            </span>
        </div>
    );
}

export function uploadFile(presignUrl, file){
    return fetch(presignUrl, {
        method: "PUT",
        body: file,
        headers: {
            'x-ms-blob-type': 'BlockBlob'
        }
    });
}

export function extractResumeFilePath(url, replaceChar) {
    const match = url.match(/\/(resumes\/[^\/?#]+)[^\/*]*$/);
    if (match) {
        const filePath = match[1].replace(/ /g, replaceChar);
        return filePath;
    } else {
        return null;
    }
}

export function extractBlogFilePath(url, replaceChar) {
    const match = url.match(/\/(blogs\/[^\/?#]+)[^\/*]*$/);
    if (match) {
        const filePath = match[1].replace(/ /g, replaceChar);
        return filePath;
    } else {
        return null;
    }
}

export function extractCompanyLogosFilePath(url, replaceChar) {
    const match = url.match(/\/(company-logos\/[^\/?#]+)[^\/*]*$/);
    if (match) {
        const filePath = match[1].replace(/ /g, replaceChar);
        return filePath;
    } else {
        return null;
    }
}

export function extractIdealCandidateAudiosFilePath(url, replaceChar) {
    const match = url.match(/\/(ideal-candidate-audios\/[^\/?#]+)[^\/*]*$/);
    if (match) {
        const filePath = match[1].replace(/ /g, replaceChar);
        return filePath;
    } else {
        return null;
    }
}

export function extractJDFilePath(url, replaceChar) {
    const match = url.match(/\/(jds\/[^\/?#]+)[^\/*]*$/);
    if (match) {
        const filePath = match[1].replace(/ /g, replaceChar);
        return filePath;
    } else {
        return null;
    }
}

export function extractProfileFilePath(url, replaceChar) {
    const match = url.match(/\/(profiles\/[^\/?#]+)[^\/*]*$/);
    if (match) {
        const filePath = match[1].replace(/ /g, replaceChar);
        return filePath;
    } else {
        return null;
    }
}

export const getPaginationLimit = () => {
    const height = window.screen.height;
    if(height < 700) return 5;
    else if(height < 800) return 8
    else if(height < 1000) return 9
    else if(height < 1300) return 12
    else if (height >= 1300) return 15
}

export const getPaginationLimitForJD = () => {
    const height = window.screen.height;
    if(height < 700) return 5;
    else if(height < 800) return 8
    else if(height < 1000) return 9
    else if(height < 1300) return 11
    else if (height >= 1300) return 15
}

export function capitalizeFirstLetterOfEachWord(str) {
    return str?.replaceAll("_", " ").split(' ').map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(' ');
}

export function isChromeBrowser(){
    return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
}

export function isSafariBrowser() {
    return /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
}

export function isFirefoxBrowser() {
    return /Firefox/.test(navigator.userAgent);
}

export function secondsToMinutes(seconds) {
    if(seconds === 'NA' || !seconds) return 'NA';
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.ceil(seconds % 60);
    return `${minutes} minutes and ${remainingSeconds} seconds`;
}

export function parseToISTFromUTC(utcTime, format = 'DD-MMM-YYYY hh:mm:A'){
const utcDate = moment.utc(utcTime);
const indiaTime = utcDate.clone().tz('Asia/Kolkata');
return indiaTime.format(format);
}

export const RESOLUTIONS = [
    { label: '480p (SD)', value: 480, width: 854, height: 480 },    // Standard definition (SD)
    { label: '720p (HD)', value: 720, width: 1280, height: 720 },   // High definition (HD)
    { label: '1080p (Full HD)', value: 1080, width: 1920, height: 1080 },  // Full high definition (FHD)
];
export const FRAME_RATES = [
    {  label: '24 fps (Cinematic)', value: 24 },
    {  label: '30 fps (Standard Video)', value: 30 },
    {  label: '60 fps (High Frame Rate)', value: 60 },
]

export function getStreamFromDevice(deviceId){
    try {
        const constraints = {
            audio: {
                noiseSuppression: true, // Enables noise suppression
                echoCancellation: true, // Reduces echo
                autoGainControl: true, // Adjusts gain automatically
                deviceId: { exact: deviceId }
            }
        };
        return navigator.mediaDevices.getUserMedia(constraints);
    } catch (error) {
        ConsoleHelper.error(`Error accessing media devices: ${JSON.stringify(error)}`);
    }
  };

export function getBrowserName() {
    if (isFirefoxBrowser()) return 'Firefox';
    if (isChromeBrowser()) return 'Chrome';
    if (isSafariBrowser()) return 'Safari';
    return 'Unknown';
}

export function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}
  
export function getRating(rating, organizationId) {
    if (organizationId === '671a28f2484d5dc1be3a13f9') {
        if (rating < 20 || !rating) return { value: 1, title: 'Poor' };
        else if (rating < 49) return { value: 2, title: 'Needs Improvement' };
        else if (rating < 71) return { value: 3, title: 'Good' };
        else if (rating < 81) return { value: 4, title: 'Very Good' };
        return { value: 5, title: 'Excellent' };
    }

    if (rating < 20 || !rating) return { value: 1, title: 'Rejected' };
    else if (rating < 49) return { value: 2, title: 'Consider Alternate Candidate' };
    else if (rating < 71) return { value: 3, title: 'Suggest On Hold' };
    else if (rating < 81) return { value: 4, title: 'Consider Proceeding' };
    return { value: 5, title: 'Ready To Proceed' };
}

export function getRatingForBpoFlow(rating) {
    if (rating < 55 || !rating) return { value: 1, title: 'No Go' };
    else if (rating >= 55 && rating < 70) return { value: 2, title: 'Not suitable for both voice and non-Voice - suggest considering alternate candidate' };
    else if (rating < 80) return { value: 3, title: 'Average for Voice, can be considered for non-voice' };
    else if (rating <= 90) return { value: 4, title: 'Good to proceed for voice' };
    return { value: 5, title: 'Excellent, Good to proceed for voice' };
}

export function getRatingForCommunication(rating) {
    if (rating < 55 || !rating) return { value: 1, title: 'Do Not Proceed' };
    else if (rating >= 55 && rating < 70) return { value: 2, title: 'Avg communication' };
    else if (rating < 80) return { value: 3, title: 'Average for Voice, can be considered for non-voice' };
    else if (rating <= 90) return { value: 4, title: 'Good to proceed for voice' };
    return { value: 5, title: 'Excellent, Good to proceed for voice' };
}

export function formatLabel(label) {
    if(!label || label?.trim().length === 0) return '-';
    return label
      .toLowerCase() // convert to lowercase
      .replace(/_/g, ' ') // replace underscores with spaces
      .replace(/\b\w/g, char => char.toUpperCase()); // capitalize each word
}

export function convertUTCToLocal(utcDateString) {
    // Parse the UTC date string into a Date object
    const utcDate = new Date(utcDateString);

    // Validate the date object to ensure it's parsed correctly
    if (isNaN(utcDate.getTime())) {
        console.error("Invalid date format");
        return null;
    }

    // Manually add the IST offset (5 hours and 30 minutes) to UTC time
    // const istOffset = 5 * 60 + 30; // 5 hours and 30 minutes in minutes
    // const istDate = new Date(utcDate.getTime() + istOffset * 60 * 1000);
    const istDate = new Date(utcDate.getTime());

    // Extract and format the IST date as "dd mm yyyy, hh:mm:ss AM/PM"
    const options = {
        day: '2-digit',
        month: 'short',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
       // second: '2-digit',
        hour12: true
    };
    const formattedIST = istDate.toLocaleString("en-IN", options);
    return formattedIST.replaceAll('-', ' ');
}

export const isBroadridge = (email) => {
    return email?.toLowerCase().includes('broadridge');
}

export const extractOverallAssessment = (markdown) => {
    if (!markdown || markdown.trim().length === 0) return null;
    
    const content =  markdown.split("- **Overall Assessment**")?.length > 1 ? 
    markdown.split("- **Overall Assessment**")[1] : null;

    return content ? `**Overall Assessment**${content}`: null;
}

// Helper function for URL validation
export const isValidUrl = (url) => {
    const urlPattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol (optional)
        '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP address
        '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // port and path (optional)
        '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // query string (optional)
        '(\\#[-a-zA-Z\\d_]*)?$', // fragment locator (optional)
        'i'
    );
    return urlPattern.test(url);
};

export function isNumberOnly(text) {
    const regex = /^\d+$/;
    return regex.test(text);
}

export function getBrowserInfo() {
    try {
        const userAgent = navigator.userAgent;
        let browserName, fullVersion;
  
        if (userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Edg") === -1 && userAgent.indexOf("OPR") === -1) {
        browserName = "Google Chrome";
        fullVersion = userAgent.match(/Chrome\/(\d+\.\d+\.\d+\.\d+)/)[1];
        } else if (userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") === -1) {
        browserName = "Safari";
        fullVersion = userAgent.match(/Version\/(\d+\.\d+)/)[1];
        } else if (userAgent.indexOf("Firefox") > -1) {
        browserName = "Mozilla Firefox";
        fullVersion = userAgent.match(/Firefox\/(\d+\.\d+)/)[1];
        } else if (userAgent.indexOf("Edg") > -1) {
        browserName = "Microsoft Edge";
        fullVersion = userAgent.match(/Edg\/(\d+\.\d+)/)[1];
        } else if (userAgent.indexOf("OPR") > -1 || userAgent.indexOf("Opera") > -1) {
        browserName = "Opera";
        fullVersion = userAgent.match(/OPR\/(\d+\.\d+)/)[1];
        } else if (userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident") > -1) {
        browserName = "Internet Explorer";
        fullVersion = userAgent.match(/(MSIE\s|rv:)(\d+\.\d+)/)[2];
        } else {
            browserName = "Unknown";
            fullVersion = "Unknown";
        }
  
        return { browserName, fullVersion };
    } catch (error) {
        return {      
            browserName: "Unknown",
            fullVersion: "Unknown"
        }
    }
}

export function convertToRange(input) {
    try {
        const regex = /(\d+)(?:-(\d+))?/; // Matches "0-3" or "2"
        const match = input?.match(regex);

        if (match) {
            const start = parseInt(match[1]) || 0; // First number
            const end = match[2] ? parseInt(match[2]) : start; // Second number or same as start
            return [start, end]; // Range from 0 to end
        }

        return input;
    } catch (error) {
        return input;
    }
}

export function getDateRange(label){
    switch(label){
        case 'Last 24 hours':
            return '24hours'
        case 'Last 7 days':
            return '7days'
        case 'Last 15 days':
            return '15days'
        case 'Last 1 month':
            return '1month'
        case 'Last 3 month':
            return '3months'
        case 'Last 6 month':
            return '6months'
        case 'Last 1 year':
            return '1year'
        default:
            return '1year'
    }
}

export function convertSkills(input, priority="mandatory", mandatory=true) {
    if (!input) {
      return []; // Handle empty or null input
    }
  
    let skillsArray;
  
    if (Array.isArray(input)) {
      skillsArray = input;
    } else if (typeof input === 'string') {
      // Split by comma, handling potential extra spaces
      skillsArray = input.split(',').map(skill => skill.trim());
    } else {
      return []; // Handle invalid input type
    }
  
    return skillsArray.map(skillName => ({
      name: skillName,
      weightage: "0",
      priority: priority,
      mandatory: mandatory,
      experience: 0
    }));
  }