import axios from "axios"

const measureDownloadSpeed = async () => {
  try {
    const fileUrl = `${process.env.REACT_APP_API_URL}/v1/screening/speed-test/download`;
    
    const startTime = performance.now();
    const response = await axios.get(fileUrl, { responseType: 'blob' });
    const endTime = performance.now();
    
    const fileSizeInBytes = response.headers['content-length']
      ? parseInt(response.headers['content-length'], 10)
      : response.data.size; // Fallback if content-length is missing
    
    const timeTakenInSeconds = (endTime - startTime) / 1000; // Convert ms to s
    
    const speedMBps = (fileSizeInBytes / timeTakenInSeconds) / (1024 * 1024); // Convert to MBps

    // console.log(`Download Speed: ${speedMBps.toFixed(2)} Mbps`);
    return speedMBps.toFixed(2);
  } catch (error) {
    console.error("Download speed test failed:", error);
    throw new Error(`Download speed test failed: ${error.message}`);
  }
};

const measurePing = async () => {
    try {
      const startTime = performance.now();
      // console.log(`${process.env.REACT_APP_API_URL}/v1/screening/speed-test/ping`)
      await axios.post(`${process.env.REACT_APP_API_URL}/v1/screening/speed-test/ping`);
      const endTime = performance.now();
      const pingTime = endTime - startTime; // Round-trip time in milliseconds
      return pingTime.toFixed(2);
    } catch (error) {
      console.log('Ping test failed', JSON.stringify(error));
      throw Error(`Ping test failed, ${JSON.stringify(error)}`)
    }
};

const NetworkManager = {
    measurePing,
    measureDownloadSpeed
}

export default NetworkManager;