import uploadAxios from "axios";
import axiosInstance from ".";
import axiosRetry from 'axios-retry';
import CommonService from "./common.service";

axiosRetry(uploadAxios, { retries: 5, retryDelay: (retryCount) => { return retryCount * 1000 }});

async function recordedFileUpload(props){
    const {fileName, sessionId, file, token} = props;
    const presignUrl = await CommonService.generatePresignUrl(sessionId, fileName, token);
    const {url} = presignUrl.data.data;
    await uploadAxios.put(url, file, {
        headers: {'x-ms-blob-type': 'BlockBlob'}
    });
}

function verifySessionKey(key){
    return axiosInstance.post('/verifySessionKey', {key })
}

function submitInterviewFeedback(sessionId, reportFeedback){
    return axiosInstance.post(`/sessions/${sessionId}/feedback`, reportFeedback)
}

function getSession(sessionId, interviewType){
    return axiosInstance.get(`/sessions/${sessionId}?interviewType=${interviewType}`)
}

const SessionService = {
    getSession,
    verifySessionKey,
    recordedFileUpload,
    submitInterviewFeedback
}

export default SessionService;