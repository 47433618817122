import axios from 'axios';
//PROD
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/v1`,
  timeout: 1 * 60 * 1000,
});

// //DEVELOPMENT
// const axiosInstance = axios.create({
//   baseURL: `http://localhost:3001/v1`,
//   timeout: 1 * 60 * 1000,
// });

// Add request interceptor
// axiosInstance.interceptors.request.use(
//   config => {
//     const accessToken = JSON.parse(localStorage.getItem('accessToken'));
//     if (accessToken) 
//       config.headers.Authorization = `Bearer ${accessToken.token}`;
//     return config;
//   },
//   error => {
//     // Log the request error
//     console.error('Request Error:', error);
//     return Promise.reject(error);
//   }
// );

// Add response interceptor
axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {

    if (error.response && error.response.status === 401 && !error.response?.config?.url.includes("/auth/")) {
      localStorage.removeItem('user');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      return window.location.href = '/auth/login';
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
