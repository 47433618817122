import { useContext, useEffect } from "react";

import NotFound from "../pages/NotFound";
import InterviewRoutes from "./InterviewRoutes";
import MaintenancePage from "../pages/MaintenancePage";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import LoadingComponent from "../components/Common/LoadingComponent";
import { MaintenanceContext } from "../contextProviders/MaintenanceContext";
import BotLipsyncTesting from "../components/Common/BotLipsSyncTesting";

export default function RootRoutes() {
    const navigate = useNavigate();
    const location = useLocation();
    const {isMaintenance, waitTime} = useContext(MaintenanceContext);

    const showMaintenancePage = isMaintenance;
  
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
        if(showMaintenancePage)
            navigate("/under-maintenance");

    }, [showMaintenancePage, navigate])


    // Render mobile warning for specific routes
    if (window.screen.width <= 850 && ["interview", "auth", "dashboard"].some(path => window.location.pathname.includes(path))) {
        return (
            <div className="h-screen bg-primary flex flex-col items-center justify-center text-center px-2 text-base font-normal gap-4">
                <p className="text-white">This website is best viewed on a desktop.</p>
                <p className="text-white">Please switch to a larger device for the best experience.</p>
            </div>
        );
    }

    if (isMaintenance === null) {
        return (
            <div className="h-screen w-screen flex justify-center items-center">
                <LoadingComponent />
            </div>
        );
    }

    return (
        <Routes>
            <Route 
                path="" 
                element={<NotFound />} 
            />
            {/* <Route 
                path="lipsync" 
                element={<BotLipsyncTesting />} 
            /> */}

            <Route 
                path="interview/*" 
                element={<InterviewRoutes />} 
            />
            <Route 
                path="notfound" 
                element={<NotFound />}
             />

            <Route 
                path="under-maintenance" 
                element={<MaintenancePage waitTime={waitTime}/>}
            />
        </Routes>
    );
}
