import {BounceLoader} from 'react-spinners';
import { getBrowserInfo } from '../../utils/common';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';

import CommonService from '../../services/common.service';
import ConsoleHelper from '../../utils/consoleHelper';
import LogService from '../../services/log.service';
import { useNavigate } from 'react-router-dom';
import { memo, useEffect, useRef, useState } from 'react';
import logo from './../../assets/icons/white-logo.svg';
import { RESUME_STAGES } from '../../utils/constants';
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { useToast } from '../../contextProviders/ToastContext';
import { MdVideocam, MdMic, MdScreenShare } from "react-icons/md";
import { useBotImages } from '../../contextProviders/BotImageContext';
import backgroundImage1 from './../../assets/images/background1.svg';
import backgroundImage2 from './../../assets/images/background2.svg';
import { useAnomalies } from '../../contextProviders/AnomaliesContext';
import { useMediaTest } from '../../contextProviders/MediaTestContext';
import { useSessionContext } from '../../contextProviders/SessionContext';
import { useMediaContext } from '../../contextProviders/InterviewMediaContext';
import PermissionsBlockedPopup from '../../components/Common/PermissionsBlockedPopup';
import MultiMonitorDetectedPopup from '../../components/Common/MultiMonitorDetectedPopup';
import InterviewDevicesSelectionComponent from '../../components/Interview/InterviewDevicesSectionComponent';
import { useBotNoddingImages } from '../../contextProviders/BotNoddingContext';

const InterviewLanding = memo(() => {
    const navigate = useNavigate();
    const azureToken = useRef();
    const azureRegion = useRef();
    const {showErrorMessage} = useToast();
    const { updateAnomalies } = useAnomalies();
    const isConnectingRef = useRef(false);
    const [recognizer, setRecognizer] = useState(null);
    const [recognizedText, setRecognizedText] = useState('');
    const {downloadImages, botImages, loadingBotImages} = useBotImages();
    const {botNoddingImages, loadingBotNoddingImages, downloadNoddingImages} = useBotNoddingImages();
    const [showVerifyMicVoicePopup, setShowVerifyMicVoicePopup] = useState(false);
    const [permissionsPopupVisibility, setPermissionsPopupVisibility] = useState(false);

    const {
        microphoneStatus,
        cameraStatus,
    } = useMediaTest();


    const {
        sessionInfo,
        setConnection,
        showInfoPopup,
        setShowInfoPopup,
        clearTranscripts,
        setIsFaceDetected,
        clearMessageQueue,
        setIsPermissionsVerified
    } = useSessionContext();
    const {  
        videoStream, 
        audioStream,
        displayStream, 
        setDisplayStream, 
    } = useMediaContext();

    const { 
        requestMicrophonePermission,
        requestCameraPermission,
        requestScreeningSharingPermission
    } = useMediaTest();

    const token = sessionInfo?.token || localStorage.getItem('sessionToken');

    const requestPermissions = async () => {
        if(!videoStream?.active) await requestCameraPermission();
        if(!audioStream?.active) await requestMicrophonePermission();
        if(!displayStream?.active) await requestScreeningSharingPermission();
    }

    useEffect(() => {
        setIsFaceDetected(false);
        updateAnomalies({ face_missing: 0});
    }, []);

    useEffect(() => {
        if(microphoneStatus !== "denied" && cameraStatus !== "denied"){
            if(permissionsPopupVisibility)
                setPermissionsPopupVisibility(false)
        }
    }, [microphoneStatus, cameraStatus])

    const initializeRecognizer = async () => {
        ConsoleHelper.log('initializeRecognizer', audioStream)
        let responseData = null;
        enabledMic(true);
        try {
            const response = await CommonService.getSpeechToken(token);
            responseData = response.data;
            azureToken.current = responseData?.token;
            azureRegion.current = responseData?.region;
        } catch (error) {
            // navigate(-1);
        }

        const speechConfig = SpeechSDK.SpeechConfig.fromAuthorizationToken(azureToken.current, azureRegion.current);
        speechConfig.speechRecognitionLanguage = 'en-IN';

        const audioConfig = SpeechSDK.AudioConfig.fromStreamInput(audioStream);
        
        const recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
    
        recognizer.recognized = (s, e) => {
            ConsoleHelper.log(`recognizer.recognized: e?.result?.text: ${e?.result?.text}  e.result.reason: ${e.result.reason}`);
            ConsoleHelper.log(`recognizer.recognized: e?.result?.text: ${e?.result?.text}  e.result.reason: ${e.result.reason}`);
            LogService.uploadLog(token, sessionInfo?.session_id, `recognizer.recognized ${e.result.reason} ${e.result.text}`);
            if (e.result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
                if(!e?.result?.text || e?.result?.text?.length === 0) return;
                
                if(e.result.text?.trim() !== 'Play.' && e.result.text.trim() !== 'BJP.'){
                    setRecognizedText(prevText => prevText + ' ' + e.result.text);
                }
            }
            else if (e.result.reason === SpeechSDK.ResultReason.NoMatch) {
                ConsoleHelper.log("NOMATCH: Speech could not be recognized.");
            }
        };

        recognizer.canceled = () => {
            ConsoleHelper.log("NOMATCH: Speech could not be recognized.");
            LogService.uploadLog(token, sessionInfo?.session_id, `recognizer.canceled`);
        };
    
        setRecognizer(recognizer);
        startListening(recognizer);
    };

    useEffect(() => {
        let timeout;
        const joinInterview = async () => {
            try {
                enabledMic(false);
                await stopListening();
                setShowVerifyMicVoicePopup(false);
                setRecognizedText('');
                navigate(`/interview/room/${localStorage.getItem('key')}`)
            } catch (error) {
                ConsoleHelper.log(JSON.stringify(error));
            }
        }
        if (recognizedText?.length > 5 && isConnectingRef?.current === false) {
            timeout = setTimeout(joinInterview, 3000);
        }

        return () => {
            if(timeout) clearTimeout(timeout);
        }
    }, [recognizedText])

    const startListening = async (recognizer) => {
        ConsoleHelper.log('startListening')
        LogService.uploadLog(token, sessionInfo?.session_id, 'startListening');
        if (recognizer) {
            try {
                await recognizer.startContinuousRecognitionAsync();
                ConsoleHelper.log("Recognition started");
            } catch (err) {
                LogService.uploadLog(token, sessionInfo?.session_id, `Error starting recognition:, ${JSON.stringify(err)}`);
                ConsoleHelper.error(`Error starting recognition:, ${JSON.stringify(err)}`);
            }
        }
    };

    const enabledMic = (desiredState = true) => {
        // Log the desired state
        ConsoleHelper.log(`enabledMic ${desiredState}`);
    
        // Check if the desired state is different from the current state
        const audioTracks = audioStream?.getAudioTracks();
    
        if (audioTracks) {
            // Determine the current state based on the tracks' enabled property
            const currentState = audioTracks[0]?.enabled; // Assuming all tracks have the same state
    
            // Only change the state if it's different
            if (currentState !== desiredState) {
                LogService.uploadLog(token, sessionInfo?.session_id, 
                    `Interview Landing enabledMic state=${desiredState} is audioStream null=${audioStream === null}`
                );
                audioTracks.forEach(track => { track.enabled = desiredState });
            }
        } else {
            LogService.uploadLog(token, sessionInfo?.session_id, 
                `Interview Landing audioStream is null; state change not possible`
            );
        }
    };
    
    const stopListening = async () => {
        LogService.uploadLog(token, sessionInfo?.session_id, 'stopListening');
        if (recognizer) {
            try {
                recognizer.close();
                await recognizer.stopContinuousRecognitionAsync();
                
                setRecognizer(null);
                LogService.uploadLog(token, sessionInfo?.session_id, 'Recognition stopped');
            } catch (err) {
                LogService.uploadLog(token, sessionInfo?.session_id, 'Error stopping recognition');
                ConsoleHelper.log(`Error stopping recognition:, ${JSON.stringify(err)}`);
            }
        }
    };


    useEffect(() => {
        async function init(){
            if(videoStream?.active && audioStream?.active && displayStream?.active){
                const screenType = displayStream.getVideoTracks()[0].getSettings().displaySurface;
                if(screenType === "monitor"){
                    setPermissionsPopupVisibility(false);
                    setIsPermissionsVerified(true);
                    if(Object.keys(botNoddingImages)?.length === 0)
                        await downloadNoddingImages();
                    if(Object.keys(botImages)?.length === 0)
                        await downloadImages();
                    else if (Object.keys(botImages)?.length > 0 && !loadingBotImages){
                        setShowVerifyMicVoicePopup(true);
                        initializeRecognizer()
                    }
                }
                else{
                    if(displayStream && displayStream.active)
                        displayStream.getTracks().forEach(track => track.stop());
                    setDisplayStream(displayStream);
                    showErrorMessage({
                        life: 5000, 
                        summary: 'Screening sharing issue', 
                        detail: 'Please share your full screen' 
                    })
                }
            }
        }

        init();
        // else if(!isFaceDetected && videoStream?.active){
        //     showErrorMessage({
        //         life: 2000, 
        //         summary: 'Face Not Detected', 
        //         detail: "We can't see your face. Please ensure you're in frame and well-lit" 
        //     })
        // }

    }, [audioStream, videoStream, displayStream, loadingBotImages, loadingBotNoddingImages, Object.keys(botNoddingImages)?.length, Object.keys(botImages)?.length]);



    const verifyMic = async () => {
        setConnection(null);
        clearTranscripts();
        clearMessageQueue();
        await requestPermissions();
        if(microphoneStatus === "denied" || cameraStatus === "denied"){
            if(microphoneStatus === "denied")
                await requestMicrophonePermission();
            if(cameraStatus === "denied")
                await requestCameraPermission();
            setPermissionsPopupVisibility(true);
        }
    }

    function getInterviewStageLabel(interviewType) {
        switch (interviewType) {
            case RESUME_STAGES.INITIAL_SCREENING:
            case RESUME_STAGES.BPO_HR_SCREENING:
                return 'Initial Screening';
            case RESUME_STAGES.VIDEO_PROFILING:
                return 'Video Profiling';
            case RESUME_STAGES.TECHNICAL_SCREENING:
                return 'Technical Assessment';
            default:
                return 'OPS Interview';
        }
    }

    const verifyChromeVersion = async () => {
        try {
            const {fullVersion} = await getBrowserInfo();
            const arr = fullVersion?.split(".");
            const version = parseInt(arr?.[0] || parseInt(fullVersion));
            if(version >= 130) setShowInfoPopup(false);
            else {
                showErrorMessage({
                    life: 5000, 
                    summary: 'Upgrade your browser version', 
                    detail: 'Please upgrade your browser to latest version and re-initiate the interview using the link in the email' 
                })
            }
        } catch (error) {
            setShowInfoPopup(false);
        }
    }

    return (
        <div className="min-h-screen w-screen bg-primary flex justify-center items-center py-5">
            <img
                alt="background" 
                src={backgroundImage1}
                className="absolute bottom-0 right-0"
            />
            <img
                alt="background" 
                src={backgroundImage2}
                className="absolute top-2 left-0"
            />
            <PermissionsBlockedPopup
                visible={permissionsPopupVisibility} 
                hide={() => setPermissionsPopupVisibility(false)}
            />
            <div className='flex 3xl:h-[90%] w-[90%] flex-col gap-3 2xl:gap-7'>
                <div className="flex justify-end">
                    <img 
                        src={logo}
                        alt='logo'
                        className='h-10 3xl:h-12'
                    />
                </div>
                <MultiMonitorDetectedPopup />
                <div className='flex gap-10 min-h-[95%] w-full'>
                    <div className='bg-white w-2/3 rounded-xl justify-between flex flex-col p-10'>
                        <div className='flex flex-col'>
                            <h2 className='font-semibold text-primary text-base xl:text-xl text-center'>
                                Welcome to the {getInterviewStageLabel(sessionInfo?.interview_type)}
                            </h2>
                            <div className='flex flex-col w-full py-10 3xl:py-20'>
                                <div className='flex w-full xl:gap-3 3xl:gap-5'>
                                    <IoIosArrowDroprightCircle 
                                        size={window.screen.width > 1500 ? 28 : 24}
                                        color='#2B4360'
                                    />
                                    <div className='w-full'>
                                        <div className='flex items-center gap-1 text-xs 3xl:text-sm'>
                                            <h4 className='text-primary font-semibold'>Job Title :</h4>
                                            <span className='text-blue'>{sessionInfo?.job_title}</span>
                                        </div>
                                        <hr className='my-6 border-0 border-b-[.2px]'/>
                                    </div>
                                </div>
                                <div className='flex w-full xl:gap-3 3xl:gap-5'>
                                    <IoIosArrowDroprightCircle 
                                         size={window.screen.width > 1500 ? 28 : 24}
                                        color='#2B4360'
                                    />
                                    <div className='w-full'>
                                        <div className='flex items-center gap-1 text-xs 3xl:text-sm'>
                                            <h4 className='text-primary font-semibold'>Duration :</h4>
                                            <span className='text-blue'>{Math.ceil(sessionInfo?.interview_duration / 60)} minutes</span>
                                        </div>
                                        <hr className='my-6 border-0 border-b-[.2px]'/>
                                    </div>
                                </div>
                                <div className='flex w-full xl:gap-3 3xl:gap-5'>
                                    <IoIosArrowDroprightCircle 
                                         size={window.screen.width > 1500 ? 28 : 24}
                                        color='#2B4360'
                                    />
                                    <div className='w-full'>
                                        <div className='flex items-center gap-1 text-xs 3xl:text-sm'>
                                            <h4 className='text-primary font-semibold'>Format: </h4>
                                            <span className='text-blue'>Athmiya will interact with you through out the screening.</span>
                                        </div>
                                        <hr className='my-6 border-0 border-b-[.2px]'/>
                                    </div>
                                </div>
                                <div className='flex w-full xl:gap-3 3xl:gap-5'>
                                    <IoIosArrowDroprightCircle 
                                         size={window.screen.width > 1500 ? 28 : 24}
                                        color='#2B4360'
                                    />
                                    <div className='w-full'>
                                        <div className='flex items-center gap-1 text-xs 3xl:text-sm'>
                                            <h4 className='text-primary font-semibold'>Permissons required: </h4>
                                        </div>
                                        <ul className='py-5 text-xs 3xl:text-sm text-brownGray flex flex-col gap-4'>
                                            <li className='flex items-center gap-4'>
                                                <MdMic size={18}/>
                                                <p>Microphone Permisson</p>
                                            </li>
                                            <li className='flex items-center gap-4'>
                                                <MdScreenShare size={18}/>
                                                <p>Screen sharing permisson</p>
                                            </li>
                                            <li className='flex items-center gap-4'>
                                                <MdVideocam size={18}/>
                                                <p>Camera Permissons</p>
                                            </li>
                                        </ul>
                                        {/* <hr className='my-6 border-0 border-b-[.2px]'/> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className='text-primary font-normal text-sm 3xl:text-base text-center'>
                            We look forward for an engaging and interactive session
                        </p>
                    </div>
                    <div className='bg-white flex rounded-xl'>
                        <InterviewDevicesSelectionComponent />
                    </div>
                </div>
                {!showVerifyMicVoicePopup && (
                    <button 
                        onClick={verifyMic}
                        className='h-9 3xl:h-12 bg-blue w-40 3xl:w-40 m-auto rounded flex justify-center items-center text-white font-semibold text-xs 3xl:text-sm'>
                        { (loadingBotImages || loadingBotNoddingImages) && <BounceLoader size={26} color="#ffffff" /> }
                        <span className={`font-semibold text-xs ${(!loadingBotImages && !loadingBotNoddingImages )? 'visible': 'hidden'}`}>Request Permissions</span>
                    </button>
                )}
            </div>
            <div className={`h-screen w-screen bg-transparent absolute left-0 top-0 z-40 justify-center items-center flex ${showInfoPopup ? 'visible': 'hidden'}`}>
                <div className='bg-white p-5 rounded-md'>
                    <h2 className='text-primary font-bold text-xl 3xl:text-xl'>Before You Join the Interview</h2>
                    <div className='pt-3 3xl:pt-5 font-normal flex flex-col mt-2 justify-center text-darkGray'>
                        <div className='pb-10 pt-2'>
                            <p className='text-base font-semibold'>Please ensure the following:</p>
                            <ul className='text-sm divide-dotted list-disc px-5 flex flex-col gap-1 font-medium mt-2'>
                                <li>You are in a quiet space with a stable internet connection.</li>
                                <li>Your microphone is working and you can be heard clearly.</li>
                                <li>You have allotted sufficient uninterrupted time for the entire duration of the interview.</li>
                                <li>Your camera is ON and your face is fully visible. Failure to comply may invalidate the interview.</li>
                                <li>Make sure your google chrome version is 130 or above.</li>
                            </ul>
                        </div>
                        <button
                            onClick={verifyChromeVersion} 
                            className='h-9 3xl:h-10 bg-blue text-white w-20 m-auto rounded-md font-semibold text-xs 3xl:text-sm'>
                            GOT IT
                        </button>
                    </div>
                </div>
            </div>
            <div className={`h-screen w-screen bg-transparent absolute left-0 top-0 z-40 justify-center items-center flex ${showVerifyMicVoicePopup ? 'visible': 'hidden'}`}>
                <div className='bg-white p-5 rounded-md flex flex-col gap-3'>
                    <h2 className='text-primary font-bold text-xl 3xl:text-xl'>Test Mic Stream</h2>
                    <div className='pt-3 3xl:pt-5 font-normal flex gap-3 flex-col justify-center text-darkGray'>
                        <p className='text-base font-normal'>Before join the interview, we need to test your mic stream, please say something.</p>
                    </div>
                    <div className='flex justify-center items-center'>
                        <div className='bg-blue text-white h-10 px-5 rounded-md flex justify-center items-center'>
                            {recognizedText && recognizedText?.trim()?.length > 3 && <span>Connecting...</span>}
                            {(!recognizedText || recognizedText?.trim()?.length <= 3) && <span>Waiting for your voice...</span>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default InterviewLanding;
