import './index.css';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import '@smastrom/react-rating/style.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import reportWebVitals from './reportWebVitals';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { ToastProvider } from './contextProviders/ToastContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ToastProvider>
      <App />
    </ToastProvider>
  </React.StrictMode>
);

reportWebVitals();
