import { useEffect, useState } from "react";
import { Outlet, useLocation} from "react-router-dom";
import { useSessionContext } from "../../contextProviders/SessionContext";
import CommonService from "../../services/common.service";
import LoadingComponent from "../../components/Common/LoadingComponent";
import { RESUME_STATUS } from "../../utils/constants";
import backgroundImage1 from './../../assets/images/background1.svg';
import backgroundImage2 from './../../assets/images/background2.svg';
import InterviewDialog from "../../components/Interview/InterviewDialog";
import { isChromeBrowser } from "../../utils/common";
import SessionService from "../../services/session.service";
import ConsoleHelper from "../../utils/consoleHelper";

export default function InterviewHome(){
    const {search, pathname} = useLocation();
    const [loading, setLoading] = useState(false);
    const {setSessionInfo, setState, state, isPermissionsVerified} = useSessionContext();

    useEffect(() => {
        const key = localStorage.getItem('key');
        if(!isPermissionsVerified && window.location.href.includes(`/room/${key}`)){
            window.location.href = `${window.location.origin}/interview/${key}`;
        }
    }, []);

    useEffect(() => {
        async function getSessionInfo(){
            try {
                setLoading(true);
                let token = search?.split('token=')[1] || null;
                const key = pathname.match(/\/interview(?:\/room)?\/([a-z]{3}-[a-z]{4}-[a-z]{3})$/)[1];
                if(key && !token){
                    const sessionKeyResponse = await SessionService.verifySessionKey(key);
                    if(sessionKeyResponse.status === 200)
                        token = sessionKeyResponse.data.data['token'];
                }
                const response = await CommonService.getSessionInfo(token);
                setLoading(false)               
                if(response.status === 200){
                    localStorage.setItem('sessionToken', token);
                    localStorage.setItem('key', key);
                    setSessionInfo({...response.data.data, token});
                    if(response.data.data?.status === RESUME_STATUS.COMPLETED || response.data.data?.status === RESUME_STATUS.EXITED)
                        setState('ALREADY_COMPLETED');
                    else 
                        setState("AUTHORISED");
                }

            } catch (error) {
                setLoading(false)
                ConsoleHelper.error(JSON.stringify(error));
                setState("EXPIRED");
            }
        }
        getSessionInfo();
    }, []);

    if(loading || state === null){
        return (
            <div className="h-screen w-screen flex justify-center items-center">
                <LoadingComponent />
            </div>
        )
    }

    return (
        <main className="w-screen h-screen bg-primary overflow-hidden relative">
            <InterviewDialog />
            <img 
                alt="background"
                src={backgroundImage1}
                className="absolute top-2 left-0 z-10"
            />
            {isChromeBrowser() &&  state === 'AUTHORISED' && (
                <div className="flex-col h-full flex overflow-hidden max-sm:!w-full w-full z-20">
                    <div className="bg-[#e5e7eb] w-full overflow-x-hidden overflow-y-scroll z-30">
                        <Outlet />
                    </div>
                </div>
            )}
            <img
                alt="background" 
                src={backgroundImage2}
                className="absolute bottom-0 right-0 z-10"
            />
        </main>
    )
}