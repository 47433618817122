import axios from "axios";
import axiosInstance from ".";

function getSessionInfo(token){
    return axiosInstance.get(`/getSessionInfo?token=${token}`)
}

function submitSessionFeedback(data){
    return axiosInstance.post('/submitSessionFeedback', data)
}

function getSpeechToken(token){
    return axiosInstance.get(`/get-speech-token?token=${token}`)
}

function reportInterviewIssue(token, data){
    return axiosInstance.post(`/report-interview-issue?token=${token}`, data)
}

async function generatePresignUrl(sessionId, filename, token){
    return axios.get(`${process.env.REACT_APP_API_URL}/api/v1/sessions/${sessionId}/generatePresignUrl?filename=${filename}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

function uploadAnomalyInfo(token, data){
    return axios.post(`${process.env.REACT_APP_API_URL}/v1/screening/session/anomaly?token=${token}`, data)
}

const CommonService = {
    getSessionInfo,
    getSpeechToken,
    uploadAnomalyInfo,
    reportInterviewIssue,
    submitSessionFeedback,
    generatePresignUrl
}

export default CommonService;